<template>
  <date-picker
    v-model="datetime"
    type="datetime"
    placeholder="YYYY/MM/DD"
    :show-time-panel="false"
    popup-class="date-picker-popup"
    :lang="lang"
    :append-to-body="false"
    :formatter="momentFormat"
    :confirm="true"
    confirm-text="確定する"
    :open="isOpen"
    :clearable="false"
    @confirm="onConfirm"
  >
    <template>
      <ValidationProvider name="生年月日" rules="required" v-slot="{ errors }">
        <the-mask
          @blur.native="onBlur"
          v-model="valueInput"
          mask="####/##/## ##:##"
          placeholder="YYYY/MM/DD"
          disabled
        />
        <span class="error f-w3">{{ errors[0] }}</span>
      </ValidationProvider>
    </template>
    <template v-slot:icon-calendar>
      <b-icon @click="onFocus" icon="calendar4"></b-icon>
    </template>
    <template v-slot:footer>
      <hr class="solid" />
      <div class="btn-cancel">
        <b-button @click="onClose" class="btn-footer cancel f-w3"
          >キャンセル</b-button
        >
      </div>
    </template>
  </date-picker>
</template>

<script>
import "vue2-datepicker/locale/ja";
export default {
  name: "DatePickerComponent",
  props: {
    initValue: [Date, null]
  },
  components: {},
  data() {
    return {
      lang: "ja",
      datetime: null,
      valueInput: "",
      form: {
        date: new Date(),
        number: ""
      },
      attrs: [],
      momentFormat: {
        stringify: date => {
          return date ? this.$dayjs(date).format("YYYY/MM/DD") : "";
        },
        parse: value => {
          if (this.$dayjs(value) >= new Date()) {
            return new Date();
          }
          return value ? this.$dayjs(value, "YYYY/MM/DD").toDate() : null;
        }
      },
      isOpen: false
    };
  },
  methods: {
    onConfirm(date) {
      if (date && new Date(date) <= new Date()) {
        this.datetime = this.$dayjs(date, "YYYY/MM/DD").toDate();
      } else {
        this.datetime = new Date();
      }
      this.isOpen = false;
    },
    onFocus() {
      this.isOpen = true;
    },
    onClose() {
      this.isOpen = false;
      this.datetime = this.initValue;
    },
    onBlur(event) {
      const value = event.target.value;
      const format = "YYYY/MM/DD";
      if (this.$dayjs(value, format).format(format) === value) {
        this.datetime = this.$dayjs(value).$d;
      } else {
        this.datetime = this.$dayjs().$d;
        this.valueInput = this.$dayjs().format("YYYY/MM/DD");
      }
    }
  },
  created() {
    this.datetime = this.initValue;
  },
  watch: {
    datetime: function(val) {
      if (val) {
        this.valueInput = this.$dayjs(val).format("YYYY/MM/DD");
      }
      this.$emit("onChange", val);
    }
  }
};
</script>

<style lang="scss" scoped>
// @import "../../assets/scss/datetimePicker.scss";
.mx-datepicker /deep/ {
  border: 1px solid #ececec;
  border-radius: 5px;
  .mx-icon-calendar {
    font-size: 13px;
    right: 15px;
    z-index: 2;
  }
}
</style>
