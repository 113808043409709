<template>
  <div id="personal-information">
    <CardHeader ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="personal-information f-w6" v-if="checkEdit">
        <div
          class="personal-information__avatar position-relative d-flex justify-content-center"
        >
          <img
            :src="user.image_url"
            alt="avatar"
            class="position-relative avatar"
          />
          <div class="filter-blur position-absolute" />
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitForm)">
            <div class="personal-information__content">
              <div
                class="d-flex justify-content-center personal-information__content-info w-100"
              >
                <div class="personal-information__content-name w-100">
                  <ValidationProvider
                    name="姓"
                    rules="required|min:1|max:255"
                    v-slot="{ errors }"
                  >
                    <p class="title">姓</p>
                    <input
                      type="text"
                      class="f-w3 w-100 input__text"
                      v-model="user.last_name_kanji"
                    />
                    <span class="error f-w3">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="personal-information__content-name w-100">
                  <ValidationProvider
                    name="名"
                    rules="required|min:1|max:255"
                    v-slot="{ errors }"
                  >
                    <p class="title">名</p>
                    <input
                      type="text"
                      class="f-w3 w-100 input__text"
                      v-model="user.first_name_kanji"
                    />
                    <span class="error f-w3">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <p class="title title__kana">フリガナ</p>
              <div
                class="d-flex justify-content-center personal-information__content-info w-100"
              >
                <div class="personal-information__content-name w-100">
                  <ValidationProvider
                    name="フリガナ"
                    rules="required|min:1"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="f-w3 w-100 input__text"
                      v-model="lastNameKana"
                      v-on:blur="handleBlurLastNameKana()"
                    />
                    <span class="error-text" ref="fontLastNameKana">
                      よみがなはひらがなで指定してください。
                    </span>
                    <span class="error-text" ref="sizeLastNameKana"
                      >ムは255文字以内にしてください</span
                    >
                    <span class="error f-w3">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="personal-information__content-name w-100">
                  <ValidationProvider
                    name="名フリガナ"
                    rules="required|min:1"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      class="f-w3 w-100 input__text"
                      v-model="firstNameKana"
                      v-on:blur="handleBlurFirstNameKana()"
                    />
                    <span class="error-text" ref="fontFirstNameKana">
                      よみがなはひらがなで指定してください。
                    </span>
                    <span class="error-text" ref="sizeFirstNameKana"
                      >ムは255文字以内にしてください</span
                    >
                    <span class="error f-w3">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div
                class="d-flex justify-content-center personal-information__content-info w-100"
              >
                <div class="personal-information__content-name w-100">
                  <ValidationProvider
                    name="生年月日"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <p class="title">生年月日</p>
                    <DatePicker
                      type="datetime"
                      :initValue="birthDay"
                      @onChange="user.date_of_birth = $event"
                      class="f-w3 w-100"
                      v-model="user.date_of_birth"
                    >
                    </DatePicker>
                    <span class="error f-w3">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="personal-information__content-name w-100">
                  <ValidationProvider
                    name="電話番号"
                    rules="required|regex:^\d{3}-\d{4}-\d{4}$"
                    v-slot="{ errors }"
                  >
                    <p class="title">電話番号</p>
                    <input
                      type="tel"
                      class="f-w3 w-100 input__text"
                      v-model="phone"
                    />
                    <span class="error f-w3">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <button class="f-w3 button">確定する</button>
          </form>
        </ValidationObserver>
      </div>
      <div class="personal-information f-w6" v-else>
        <div
          class="personal-information__avatar position-relative d-flex justify-content-center"
        >
          <img
            :src="user.image_url"
            alt="avatar"
            class="position-relative avatar"
          />
          <div class="filter-blur position-absolute" />
        </div>
        <div class="personal-information__content">
          <div
            class="d-flex justify-content-center personal-information__content-info w-100"
          >
            <div class="personal-information__content-name w-100">
              <p class="title">姓</p>
              <input
                type="text"
                class="f-w3 w-100 input__disable"
                v-model="user.last_name_kanji"
                disabled
              />
            </div>
            <div class="personal-information__content-name w-100">
              <p class="title">姓</p>
              <input
                type="text"
                class="f-w3 w-100 input__disable"
                v-model="user.first_name_kanji"
                disabled
              />
            </div>
          </div>
          <p class="title title__kana">フリガナ</p>
          <div
            class="d-flex justify-content-center personal-information__content-info w-100"
          >
            <div class="personal-information__content-name w-100">
              <input
                type="text"
                class="f-w3 w-100 input__disable"
                v-model="user.last_name_kana"
                disabled
              />
            </div>
            <div class="personal-information__content-name w-100">
              <input
                type="text"
                class="f-w3 w-100 input__disable"
                v-model="user.first_name_kana"
                disabled
              />
            </div>
          </div>
          <div
            class="d-flex justify-content-center personal-information__content-info w-100"
          >
            <div class="personal-information__content-name w-100">
              <p class="title">生年月日</p>
              <input
                type="text"
                class="f-w3 w-100 input__disable"
                v-model="user.date_of_birth"
                disabled
              />
            </div>
            <div class="personal-information__content-name w-100">
              <p class="title">電話番号</p>
              <input
                type="text"
                class="f-w3 w-100 input__disable"
                v-model="user.phone"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader.vue";
import CardFooter from "@/components/CardFooter.vue";
import DatePicker from "@/views/Common/DatePicker";
export default {
  metaInfo() {
    return {
      title: "個人情報",
      titleTemplate: "%s | PreGo"
    };
  },

  computed: {
    birthDay() {
      return new Date(this.user.date_of_birth);
    },
    checkEdit() {
      if (
        this.$route.fullPath === "/my-page/profile/personal-information/edit" ||
        this.$route.fullPath === "/cast/my-page/personal/information/edit"
      ) {
        return true;
      }
      return false;
    }
  },
  components: {
    CardHeader,
    CardFooter,
    DatePicker
  },
  created() {
    this.user = this.$store.getters["auth/user"];
    this.phone = this.user.phone;
    this.lastNameKana = this.user.last_name_kana;
    this.firstNameKana = this.user.first_name_kana;
  },
  data() {
    return {
      headerTitle: {
        text: "個人情報",
        isShowTitle: true,
        isShowLogo: false
      },
      user: null,
      check: null,
      phone: null,
      lastNameKana: "",
      firstNameKana: ""
    };
  },
  watch: {
    phone: function() {
      if (
        this.phone.length == 11 &&
        Number.isInteger(Number(this.phone)) &&
        !this.phone.includes(" ")
      ) {
        let phoneStr = String(this.phone);
        this.phone =
          phoneStr.substr(0, 3) +
          "-" +
          phoneStr.substr(3, 4) +
          "-" +
          phoneStr.substr(7, 4);
      }
    },
    lastNameKana: function() {
      this.checkFontKana(
        "fontLastNameKana",
        "sizeLastNameKana",
        this.lastNameKana
      );
    },
    firstNameKana: function() {
      this.checkFontKana(
        "fontFirstNameKana",
        "sizeFirstNameKana",
        this.firstNameKana
      );
    }
  },
  methods: {
    handleBlurFirstNameKana() {
      this.firstNameKana = this.firstNameKana.trim();
    },
    handleBlurLastNameKana() {
      this.lastNameKana = this.lastNameKana.trim();
    },
    checkFontKana(refFont, refSize, text) {
      this.checkSize(refFont, refSize, text);
      if (text.length <= 255) {
        for (let i = 0; i < text.length; i++) {
          if (!text[i].match(/[\u30a0-\u30ff]/) && text[i] != " ") {
            this.$refs[refFont].classList.add("error-text-font");
            this.$refs[refSize].classList.remove("error-text-size");
            break;
          } else {
            this.$refs[refFont].classList.remove("error-text-font");
            this.$refs[refSize].classList.remove("error-text-size");
          }
        }
      }
    },
    checkSize(refFont, refSize, text) {
      if (text.length > 255) {
        this.$refs[refFont].classList.remove("error-text-font");
        this.$refs[refSize].classList.add("error-text-size");
      } else if (text.length == 0) {
        this.$refs[refFont].classList.remove("error-text-font");
        this.$refs[refSize].classList.remove("error-text-size");
      }
    },
    async submitForm() {
      let listOptionsErrorFont = document.getElementsByClassName(
        "error-text-font"
      );
      let listOptionsErrorSize = document.getElementsByClassName(
        "error-text-size"
      );
      this.user.date_of_birth = this.$dayjs(this.user.date_of_birth).format(
        "YYYY/MM/DD"
      );
      var today = new Date();
      var birthDate = new Date(this.user.date_of_birth);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 20) {
        this.$toast("20歳以上に指定してください", "通知", "danger");
        return;
      }
      if (
        listOptionsErrorFont.length == 0 &&
        listOptionsErrorSize.length == 0
      ) {
        this.$root.$refs.loading.start();
        let formData = new FormData();
        formData.append("last_name_kanji", this.user.last_name_kanji);
        formData.append("first_name_kanji", this.user.first_name_kanji);
        formData.append("last_name_kana", this.lastNameKana);
        formData.append("first_name_kana", this.firstNameKana);
        formData.append("phone", this.phone);
        formData.append("date_of_birth", this.user.date_of_birth);
        formData.append("_method", "PUT");
        await this.$store
          .dispatch("editProfile/editProfile", formData)
          .then(() => {
            this.$root.$refs.loading.finish();
            this.$toast("更新情報の成功", "題名", "success");
            this.$router.go(-1);
          })
          .catch(() => {
            this.$root.$refs.loading.finish();
            this.$toast("情報の更新に失敗しました", "通知", "danger");
          });
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/_fontFamily.scss";

#personal-information {
  .app-container {
    .app-container__content {
      .card-header-body {
        z-index: 11;
      }
    }
  }
  .personal-information {
    color: #000000;
    padding: 0 19px;
    &__avatar {
      margin: auto;
      margin-bottom: 30px;
      margin-top: 20px;
      width: 143px;
      height: 143px;
      border-radius: 50%;
      z-index: 9;
      .avatar {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      .filter-blur {
        width: 96px;
        top: 50px;
        height: 96px;
        background-color: #bbbbbb;
        filter: blur(10px);
        z-index: 1;
      }
    }
    &__content {
      text-align: left;
      .title {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 10px;
        &__kana {
          margin-left: 5.5px;
        }
      }
      &-name {
        padding: 0 5.5px;
        .error-text {
          display: none;
        }
        .error-text-size,
        .error-text-font,
        .error {
          display: inline !important;
          color: #ea4545;
          font-family: "Hiragino Kaku Gothic Pro W3";
        }
        .input__text {
          height: 40px;
          border: 1px solid #ececec;
          border-radius: 5px;
          padding-left: 18px;
          font-size: 14px;
        }
        .input__disable {
          height: 40px;
          background-color: #ececec;
          border: 1px solid #ececec;
          border-radius: 5px;
          padding-left: 18px;
          font-size: 14px;
          color: #000000;
        }
        .mx-datepicker {
          height: 40px;
          .mx-input-wrapper {
            height: 100%;
            input {
              width: 100%;
              height: 100%;
              border-radius: 5px;
              border: 1px #eeeeee;
              padding-left: 18px;
              color: black;
              font-size: 14px;
            }
          }
        }
        .mx-datepicker-footer {
          display: flex;
          justify-content: center;
        }
        .mx-datepicker-btn-confirm {
          background-color: #1284e7;
          color: white;
          line-height: 1;
          font-size: 1rem;
          width: 105px;
        }
        .btn-cancel {
          padding-right: 10px;
        }
      }
      &-info {
        margin-bottom: 25px;
      }
    }
    .button {
      width: 100%;
      font-size: 16px;
      padding: 12px 0;
      background: #464d77;
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 20px;
      height: 45px;
      color: white;
    }
  }
}

@media screen and (min-width: 1200px) {
  #personal-information {
    .personal-information {
      color: #000000;
      padding: 0 195px;
      &__avatar {
        margin: auto;
        margin-bottom: 63px;
        margin-top: 68px;
        width: 195px;
        height: 195px;
        .filter-blur {
          width: 118px;
          top: 80px;
          height: 118px;
          background-color: #bbbbbb;
          filter: blur(10px);
          z-index: 1;
        }
      }
      &__content {
        text-align: left;
        .title {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 15px;
          &__kana {
            margin-left: 12px;
          }
        }
        &-name {
          padding: 0 12px;
          input {
            height: 50px;
            padding-left: 20px;
            font-size: 16px;
          }
        }
        &-info {
          margin-bottom: 25px;
        }
        .input__disable {
          height: 50px;
        }
        .input__text {
          height: 50px;
        }
        .mx-datepicker {
          height: 50px;
        }
      }
      .button {
        width: 440px;
        height: 55px;
        margin-top: 25px;
        font-size: 20px;
        color: white;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 40px;
  padding-bottom: 150px;
  // height: calc(100vh - 108px);
}
@media screen and (min-width: 1200px) {
  .content-scroll {
    // height: calc(100vh - 152px);
    padding-top: 60px;
    padding-bottom: 150px;
  }
}
</style>
